var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AlertBox", {
    attrs: {
      title: "Upgrade to unlock more features!",
      description: "This service is available on our Elite account only.",
      severity: "info",
      link: { to: "Subscription", text: "Upgrade to premium" },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }